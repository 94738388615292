<template>
  <v-card class="mx-auto" :disabled="table_loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{
                  name: 'doctors',
                }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span
            class="text-h6 ml-1"
            v-text="
              `${$route.meta.title} ${
                table_loading ? '' : `(${table_data.length})`
              }`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
        <v-col cols="12" xs="12" md="6">
          <v-select
            label="Estado"
            v-model="filter"
            :items="filters"
            item-value="value"
            :item-text="(v) => v.name"
            dense
            @change="getTableData"
          />
        </v-col>
        <v-col cols="12" xs="12" md="6">
          <v-text-field
            v-model="table_search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-data-table
            :headers="table_headers"
            :search="table_search"
            :items="table_data"
            :loading="table_loading"
            :items-per-page="15"
            dense
          >
            <template v-slot:item.index="{ item }">
              <b v-text="item.index" />
            </template>
            <template v-slot:item.doctor_revision="{ item }">
              <div>
                <v-icon
                  v-if="item.doctor_revision"
                  small
                  :color="item.doctor_revision.accepted ? 'info' : 'warning'"
                >
                  {{
                    item.doctor_revision.accepted ? "mdi-check" : "mdi-alert"
                  }}
                </v-icon>
                <span v-else v-text="'-'" />
              </div>
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    :color="item.accepted == 0 ? 'error' : 'amber darken-3'"
                    v-on="on"
                    x-small
                    dark
                    fab
                    @click.prevent="doctorPrerevisionDialog(item)"
                  >
                    <v-icon> mdi-file-sign </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Revisar'" />
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog
      v-model="doctor_prerevision_dialog"
      scrollable
      persistent
      max-width="900"
    >
      <v-card
        tile
        :disabled="doctor_prerevision_dialog_loading"
        :loading="doctor_prerevision_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> MÉDICO </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="doctor_prerevision_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="doctor_prerevision_data">
          <v-row>
            <v-col cols="12" />
            <v-col v-if="data.doctor_revision" cols="12">
              <v-alert
                :type="data.doctor_revision.accepted ? 'info' : 'warning'"
                class="mb-0"
                dense
              >
                <span>
                  Existe una revisión
                  {{
                    data.doctor_revision.accepted ? "aceptada" : "en proceso"
                  }}
                  ({{ data.doctor_revision.id }})
                </span>
              </v-alert>
            </v-col>
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span
                      class="text-caption"
                      v-text="`PRE REVISIÓN | ${data.folio}`"
                    />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" v-if="data.accepted == 0">
                      <v-alert type="error" class="mb-0" dense>
                        <b>RECHAZADA</b>
                        <br />
                        <span v-text="data.observation" />
                      </v-alert>
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Nombre"
                        :value="`IDM ${data.doctor_id} | ${data.doctor_name}`"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Especialidad"
                        :value="data.specialty_type"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Consult. | Hosp."
                        :value="data.provider_trade_name"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData label="Banco" :value="data.bank" />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Número de cuenta"
                        :value="data.account_number"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData label="CLABE" :value="data.clabe" />
                    </v-col>
                    <v-col cols="12" xs="12" md="12">
                      <span
                        class="text-caption font-weight-bold"
                        v-text="'Cuenta bancaria'"
                      />
                      <v-tooltip v-if="data.bank_account_pdf" right>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            small
                            v-on="on"
                            :href="
                              url_documents +
                              '/doctors/' +
                              data.bank_account_pdf
                            "
                            target="_blank"
                          >
                            <v-icon small>mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span v-text="'Ver'" />
                      </v-tooltip>
                      <v-icon v-else small>mdi-alert</v-icon>
                    </v-col>
                    <v-col
                      v-if="
                        data.accepted == null &&
                        doctor_prerevision_data.accepted == null
                      "
                      cols="6"
                    >
                      <v-btn
                        block
                        color="error"
                        dark
                        @click.prevent="
                          doctor_prerevision_data.accepted = false
                        "
                        small
                      >
                        RECHAZAR
                      </v-btn>
                    </v-col>
                    <v-col
                      v-if="
                        data.accepted == null &&
                        doctor_prerevision_data.accepted == null
                      "
                      cols="6"
                    >
                      <v-btn
                        block
                        color="success"
                        dark
                        @click.prevent="doctor_prerevision_data.accepted = true"
                        small
                      >
                        ACEPTAR
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col v-if="doctor_prerevision_data.accepted != null" cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'INFORMACIÓN'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-form
                    v-on:submit.prevent
                    ref="doctor_prerevision_form"
                    lazy-validation
                  >
                    <v-row
                      v-if="doctor_prerevision_data.accepted == true"
                      dense
                    >
                      <v-col cols="12" xs="12" md="4">
                        <v-autocomplete
                          label="Banco*"
                          v-model="doctor_prerevision_data.bank_id"
                          :items="banks"
                          item-value="id"
                          :item-text="(v) => v.bank"
                          :loading="banks_loading"
                          dense
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          label="Número de cuenta*"
                          v-model="doctor_prerevision_data.account_number"
                          type="text"
                          maxlength="15"
                          dense
                          counter
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          label="CLABE*"
                          v-model="doctor_prerevision_data.clabe"
                          type="text"
                          maxlength="18"
                          dense
                          counter
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-file-input
                          label="Cuenta bancaria (PDF)"
                          v-model="
                            doctor_prerevision_data.document_bank_account_0
                          "
                          accept=".pdf"
                          show-size
                          dense
                          prepend-icon=""
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-file-input
                          label="Valid. cuenta (PDF)*"
                          v-model="
                            doctor_prerevision_data.document_account_validation_0
                          "
                          accept=".pdf"
                          :rules="rules.required"
                          show-size
                          dense
                          prepend-icon=""
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="6">
                        <v-autocomplete
                          label="Especialidad*"
                          v-model="doctor_prerevision_data.specialty_type_id"
                          :items="specialty_types"
                          item-value="id"
                          :item-text="(v) => v.specialty_type"
                          :loading="specialty_types_loading"
                          dense
                          @change="specialtyReset"
                        />
                      </v-col>
                      <v-col
                        v-if="doctor_prerevision_data.specialty_type_id"
                        cols="12"
                        xs="12"
                        md="6"
                      >
                        <v-text-field
                          label="Cédula"
                          v-model="doctor_prerevision_data.license"
                          type="text"
                          maxlength="15"
                          dense
                          counter
                        />
                      </v-col>
                      <v-col
                        v-if="doctor_prerevision_data.specialty_type_id"
                        cols="12"
                      >
                        <v-file-input
                          label="Cédula(PDF)"
                          v-model="
                            doctor_prerevision_data.document_identification_card_0
                          "
                          accept=".pdf"
                          show-size
                          dense
                          prepend-icon=""
                        />
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="doctor_prerevision_data.accepted == false"
                      dense
                    >
                      <v-col cols="12">
                        <v-textarea
                          label="Observación*"
                          v-model="doctor_prerevision_data.observation"
                          :rules="rules.required"
                          rows="1"
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col
                        v-if="doctor_prerevision_data.accepted != null"
                        cols="12"
                      >
                        <v-btn
                          block
                          :color="
                            doctor_prerevision_data.accepted == true
                              ? 'amber darken-3'
                              : 'error'
                          "
                          dark
                          @click.prevent="doctorPrerevisionSubmit"
                          small
                        >
                          {{
                            doctor_prerevision_data.accepted == true
                              ? "CARGAR"
                              : "CONFIRMAR RECHAZO"
                          }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" v-if="login.permissions.doctor_prerevision.delete">
              <v-btn
                color="error"
                small
                @click.prevent="prerevisionDesactivate"
              >
                Eliminar
                <v-icon small> mdi-file-remove </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgAlert,
  msgConfirm,
  rules,
  toFormData,
  URL_DOCUMENTS,
} from "../../control";
import ViewData from "../../components/ViewData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    ViewData,
    FaqDlg,
  },
  data() {
    return {
      login: this.$store.getters.getLogin,
      url_documents: URL_DOCUMENTS,
      rules: rules(),
      table_loading: false,
      table_data: [],
      table_search: "",
      table_headers: [
        { text: "#", filterable: true, value: "index" },
        { text: "ID", filterable: true, value: "folio" },
        { text: "Fecha", filterable: true, value: "created_at" },
        { text: "Creado por", filterable: true, value: "created_by.email" },
        { text: "IDM", filterable: true, value: "doctor_id" },
        { text: "Médico", filterable: true, value: "doctor.full_name" },
        { text: "Revisión", filterable: true, value: "doctor_revision" },
        { text: "", value: "action", sortable: false, width: "54px" },
      ],
      data: null,
      doctor_prerevision_data: null,
      doctor_prerevision_dialog: false,
      doctor_prerevision_dialog_loading: false,
      banks: [],
      banks_loading: true,
      filter: 1,
      filters: [
        {
          value: 1,
          name: "PENDIENTES",
        },
        {
          value: 3,
          name: "PENDIENTES | SERVICIOS PAGOS AL DÍA",
        },
        {
          value: 2,
          name: "RECHAZADOS",
        },
      ],
      specialty_types: [],
      specialty_types_loading: true,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getTableData() {
      this.table_loading = true;
      this.table_data = [];

      Axios.get(
        `${URL_API}/doctors/prerevision?filter=${this.filter}`,
        headersToken(this.login.token)
      ).then((resp) => {
        this.table_data = resp.data.data;
        this.table_loading = false;
      });
    },
    doctorPrerevisionDialog(item) {
      this.data = {
        folio: item.folio,
        bank: item.bank.bank,
        account_number: item.account_number,
        clabe: item.clabe,
        bank_account_pdf: item.bank_account_pdf,
        doctor_id: item.doctor_id,
        doctor_name: item.doctor.full_name,
        specialty_type: item.doctor.specialty
          ? item.doctor.specialty.specialty_type.specialty_type
          : null,
        provider_trade_name: item.doctor.consulting_room
          ? item.doctor.consulting_room.provider.trade_name
          : null,
        accepted: item.accepted,
        observation: item.observation,
        doctor_revision: item.doctor_revision,
      };

      this.doctor_prerevision_data = {
        id: item.id,
        bank_id: item.bank_id,
        account_number: item.account_number,
        clabe: item.clabe,
        bank_account_pdf: item.bank_account_pdf,
        document_bank_account_0: null,
        document_account_validation_0: null,
        accepted: null,
        observation: null,
        no_specialty: true,
        specialty_type_id: null,
        license: "",
        document_identification_card_0: null,
      };

      this.doctor_prerevision_dialog_loading = false;
      this.doctor_prerevision_dialog = true;
    },
    doctorPrerevisionSubmit() {
      if (this.$refs.doctor_prerevision_form.validate()) {
        this.$swal
          .fire(
            msgConfirm(
              `¿Confirma ${
                this.doctor_prerevision_data.accepted ? "aceptar" : "rechazar"
              } la prerevisión?`
            )
          )
          .then((resp) => {
            if (resp.isConfirmed) {
              this.doctor_prerevision_dialog_loading = true;

              Axios.post(
                `${URL_API}/doctors/prerevision/accept`,
                toFormData(this.doctor_prerevision_data),
                headersToken(this.login.token)
              ).then((resp) => {
                this.$swal.fire(
                  msgAlert(
                    resp.data.success ? "success" : "error",
                    resp.data.message
                  )
                );

                if (resp.data.success) {
                  this.doctor_prerevision_dialog = false;
                  this.getTableData();
                } else {
                  console.log(resp.data.message);
                }

                this.doctor_prerevision_dialog_loading = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    doctorPrerevisionReject() {
      this.$swal
        .fire(msgConfirm(`¿Confirma rechazar la prerevisión?`))
        .then((resp) => {
          if (resp.isConfirmed) {
            this.doctor_prerevision_data.accepted = false;
            this.doctorPrerevisionSubmit();
          }
        });
    },
    doctorPrerevisionHandle() {
      if (this.$refs.doctor_prerevision_form.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma cargar la información para revisión?`))
          .then((resp) => {
            if (resp.isConfirmed) {
              this.doctorPrerevisionSubmit();
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    specialtyReset() {
      if (this.doctor_prerevision_data.specialty_type_id == null) {
        this.doctor_prerevision_data.license = "";
        this.doctor_prerevision_data.document_identification_card_0 = null;
      }
    },
    prerevisionDesactivate() {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar la pre revisión?`))
        .then((response) => {
          if (response.isConfirmed) {
            this.doctor_prerevision_dialog_loading = true;

            Axios.delete(
              URL_API +
                "/doctors/prerevision/" +
                this.doctor_prerevision_data.id,
              headersToken(this.login.token)
            ).then((response) => {
              this.$swal.fire(
                msgAlert(
                  response.data.success ? "success" : "error",
                  response.data.message
                )
              );

              if (response.data.success) {
                this.doctor_prerevision_dialog = false;
                this.getTableData();
              }

              this.doctor_prerevision_dialog_loading = false;
            });
          }
        });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    Axios.get(
      `${URL_API}/catalog?name=banks&no_order=false`,
      headersToken(this.login.token)
    ).then((resp) => {
      this.banks = resp.data.data;
      this.banks_loading = false;
    });

    Axios.get(
      `${URL_API}/catalog?name=specialty_types&no_order=false`,
      headersToken(this.login.token)
    ).then((response) => {
      this.specialty_types = response.data.data;
      this.specialty_types.push({
        id: null,
        specialty_type: "NO AGREGAR",
      });
      this.specialty_types_loading = false;
    });

    this.getTableData();
  },
};
</script>